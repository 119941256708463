

<template>
  <div class="main-conent main-conent-minheight" v-loading="loading">
    <el-card shadow="never" class="border-none">
      <div slot="header">
        <el-row>
          <el-col :span="20">
            <el-radio-group v-model="currentState" size="small" @change="handleStateChange">
              <el-radio-button value="0" label="待处理"></el-radio-button>
              <el-radio-button value="1" label="跟踪中"></el-radio-button>
              <el-radio-button value="2" label="已处理"></el-radio-button>
              <el-radio-button value="3" label="已放弃"></el-radio-button>
              <el-radio-button value="101" label="已删除"></el-radio-button>
            </el-radio-group>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="downloadExcel">下载所有意向</el-button>
          </el-col>
        </el-row>
      </div>
      <el-table :data="tableData" stripe fit>
        <el-table-column prop="id" label="昵称" width="80">
          <template slot-scope="scope">
            <div class="Middle">
              <el-avatar size="small" :src="scope.row.appUserAvatar"></el-avatar>
              <label>{{scope.row.appUserName}}</label>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="编号" />
        <el-table-column prop="companyName" label="企业名称" />
        <el-table-column prop="contactName" label="联络人" />
        <el-table-column prop="email" label="邮箱" />
        <el-table-column prop="createDateTime" label="创建日期" />
        <el-table-column prop="isPublish" label="状态">
          <template slot-scope="scope">
            <el-tag :type="StateTagType(scope.row.state)" disable-transitions>
              {{StateTagDesc(scope.row.state)}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="100">
          <template slot-scope="scope">
            <el-button type="text" @click="handleUpdateState(scope.row.id,1)">跟踪</el-button>
            <el-button type="text" @click="handleUpdateState(scope.row.id,2)">完成</el-button>
            <el-button type="text" @click="handleUpdateState(scope.row.id,3)">放弃</el-button>
            <el-button type="text" @click="handleUpdateState(scope.row.id,101)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="margin-t16 text-right">

      </div>

    </el-card>
  </div>
</template>
<script>
import { getList, updateState, download } from '@/service/applyform.js';

export default {
  data() {
    return {
      dialogFormVisible: false,
      currentState: '待处理',
      loading: false,
      searchOpen: false,
      totalCount: 0,
      pointFormData: {
        appUserId: 0,
        point: 0,
        remark: ''
      },
      searchForm: {
        page: 1,
        pageSize: 20,
        state: 0
      },
      tableData: []
    };
  },
  created() {
    this.dataBinding();
  },
  computed: {
    StateTagType() {
      return function (state) {
        if (state === 2)
          return 'primary';
        else if (state === 101)
          return 'danger';
        else
          return 'info';
      };
    },
    StateTagDesc() {
      return function (state) {
        if (state === 2)
          return '已处理';
        else if (state === 0)
          return '待处理';
        else if (state === 1)
          return '跟踪中';
        else if (state === 3)
          return '已放弃';
        else if (state === 101)
          return '已删除';
        else
          return 'info';
      };
    }
  },
  methods: {
    //加载页面数据
    dataBinding() {
      this.loading = true;
      getList(this.searchForm).then(res => {
        this.totalCount = res.total;
        this.tableData = res.list;
        this.loading = false;
      });
    },
    //选中状态变更
    handleStateChange(label) {
      if (label === '待处理')
        this.searchForm.state = 0;
      else if (label === '跟踪中')
        this.searchForm.state = 1;
      else if (label === '已处理')
        this.searchForm.state = 2;
      else if (label === '已放弃')
        this.searchForm.state = 3;
      else if (label === '已删除')
        this.searchForm.state = 101;
      this.dataBinding();
    },
    //提交修改状态
    handleUpdateState(id, state) {
      this.loading = true;
      let form = { id: id, state: state };
      updateState(form).then(res => {
        if (res === true) {
          this.dataBinding();
        }
        this.loading = false;
      });
    },
    //下载所有意向EXCEL
    downloadExcel() {
      this.loading = true;
      download().then(res => {
        this.loading = false;
      });
    }
  }
};
</script>
<style scoped>
.Middle {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
</style>