import request from '@/service/lib/request';

export function getList(data) {
  return request({
    url: '/applyform/applyforms',
    method: 'get',
    params: data
  });
}

export function updateState(data) {
  return request({
    url: '/applyform/state',
    method: 'post',
    data
  });
}

export function download() {
  return request({
    url: '/applyform/download',
    method: 'get'
  });
}